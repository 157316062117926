<template>
  <v-container id="issue-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" :title="$t(prop_title)" class="px-5 py-3">
      <dialog-message :dialog_message="dialog_message" :message="message"></dialog-message>
      <dialog-progress :dialog_message="progress_dialog" :message="progress_message"
        v-on:close="close"></dialog-progress>
      <slot name="panel">
        <v-row>
          <v-col>
            <v-select v-model="choose_urban" :items="urbans" item-text="name" item-value="id"
              :label="$t('issue.chooseUrban')" hide-details persistent-hint return-object filled></v-select>
          </v-col>
          <v-col cols="auto" v-if="false">
            <v-btn class="mx-2" small fab dark color="#A0A0A0" @click="showDecimalDialog = true">
              <v-icon class="mx-auto" dark>
                mdi-format-list-bulleted-square
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <!-- Decimal Places Dialog -->
            <v-dialog v-model="showDecimalDialog" max-width="400">
              <v-card>
                <v-card-title>{{ $t("plan.decimal_places") }}</v-card-title>
                <v-card-text class="my-2">
                  <v-slider v-model="tempDecimalPlaces" :min="2" :max="10" step="1" thumb-label="always" ticks="always"
                    tick-size="4">
                    <template v-slot:prepend>
                      <label>2</label>
                    </template>
                    <template v-slot:append>
                      <label>10</label>
                    </template>
                  </v-slider>
                  <div class="text-center">
                    小數點進位: {{ tempDecimalPlaces }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey darken-1" text @click="showDecimalDialog = false">{{$t("common.cancel")}}</v-btn>
                  <v-btn color="primary" text @click="saveDecimalPlaces">{{ $t("common.save") }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col class="text-right">
            <v-btn color="primary" dark class="mx-2" @click="onRetrieveTranscribe()" :disabled="!choose_urban">
              <v-icon left>mdi-pencil-plus</v-icon>
              {{ $t("plan.retrieve") }}
            </v-btn>
            <v-btn color="secondary" dark class="mx-2" @click="exportData()" v-show="choose_urban">
              <v-icon left>mdi-pencil-plus</v-icon>
              {{ $t("plan.export-transcribe") }}
            </v-btn>
          </v-col>
        </v-row>
      </slot>
      <!-- display 善打 -->
      <v-row>
        <v-data-table :items="items" :headers="headers" hide-default-header v-if="items.length > 0">
          <template v-slot:header="{ props }">
            <tbody>
              <tr class="ro1">
                <td rowspan="3" class="customer-header">
                  <p>序號</p>
                </td>
                <td rowspan="3" class="customer-header-name">
                  <p>所有權人/管理人</p>
                </td>
                <td colspan="9" class="customer-header">
                  <p>土地</p>
                </td>
                <td colspan="12" class="customer-header">
                  <p>建物</p>
                </td>
                <td rowspan="3" class="customer-header-address">
                  <p>所有權人地址</p>
                </td>
              </tr>
              <tr class="ro2">
                <td rowspan="2" class="customer-header-small">
                  <p>鄉鎮市區</p>
                </td>
                <td rowspan="2" class="customer-header-medium">
                  <p>地段</p>
                </td>
                <td rowspan="2" class="customer-header-medium">
                  <p>小段</p>
                </td>
                <td rowspan="2" class="customer-header-small">
                  <p>地號</p>
                </td>
                <td rowspan="2" class="customer-header-small">
                  <p>&nbsp;土地面積(平方公尺)</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>權利範圍</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>&nbsp;持分面積(平方公尺)</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>總計面積(平方公尺)</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>土地佔比(%)</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>建號</p>
                </td>
                <td rowspan="2" class="customer-header-address">
                  <p>建物門牌</p>
                </td>
                <td rowspan="2" class="customer-header-small">
                  <p>坐落基地</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>主建面積(平方公尺)</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>附建面積(平方公尺)</p>
                </td>
                <td colspan="3" class="customer-header">
                  <p>共同使用部分</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>權利範圍</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>持分面積(平方公尺)</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>總計面積(平方公尺)</p>
                </td>
                <td rowspan="2" class="customer-header">
                  <p>建物佔比(%)</p>
                </td>
              </tr>
              <tr class="ro3">
                <td class="customer-header">
                  <p>&nbsp;面積</p>
                </td>
                <td class="customer-header">
                  <p>權利範圍</p>
                </td>
                <td class="customer-header">
                  <p>&nbsp;持分面積</p>
                </td>
              </tr>
            </tbody>
          </template>

          <template v-slot:item="{ item, index }">
            <tr v-for="(detail, row_ind) in item.data">
              <td v-for="(header, header_index) in headers" :key="header_index"
                v-if="allowTD(detail, header.value, detail.rowsapn)" :rowspan="calculateRowSpan(detail, header.value)"
                :style="{
                  backgroundColor: detail.SN % 2 === 0 ? '#FFF5CE' : '',
                  border: 'thin solid rgba(0, 0, 0, 0.12)',
                }">
                {{ displayItem(detail[header.value], header.type) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import CommonUtil from "@/utils/commonutil.js";
import UrbanMixin from "../common/UrbanMixin.vue";
export default {
  props: {
    prop_title: {
      type: String,
      default: "title.Transcribe",
    },
    prop_emit: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    progress_dialog: false,
    progress_message: {
      title: "",
      text: "",
    },
    dialog_message: false,
    message: {
      title: "",
      text: "",
    },
    options: {},
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    headers: [
      {
        text: "序號",
        align: "start",
        value: "SN",
      },
      { text: "所有權人/管理人", value: "Owner", width: "200px" },
      { text: "行政區", value: "Dist" },
      { text: "段", value: "Sec" },
      { text: "小段", value: "SubSec" },
      { text: "地號", value: "LN" },
      { text: "土地面積(平方公尺)", value: "LA" },
      { text: "土地權利範圍", value: "LRR" },
      { text: "土地持分面積(平方公尺)", value: "LSA", type: "float" },
      { text: "土地總計面積(平方公尺)", value: "TLA", type: "float" },
      { text: "土地佔比(%)", value: "LS", type: "percentage" },
      { text: "建號", value: "BN" },
      { text: "建物門牌", value: "BA", width: "200px" },
      { text: "坐落基地", value: "BL" },
      { text: "主建面積(平方公尺)", value: "MBA", type: "float" },
      { text: "附建面積(平方公尺)", value: "ABA", type: "float" },
      { text: "共同使用部分_面積", value: "CA", type: "float" },
      { text: "共同使用部分_權利範圍", value: "CARR" },
      { text: "共同使用部分_持分面積", value: "CSA" },
      { text: "建物權利範圍", value: "BRR" },
      { text: "建物持分面積(平方公尺)", value: "BSA", type: "float" },
      { text: "建物總計面積(平方公尺)", value: "TBA", type: "float" },
      { text: "建物佔比(%)", value: "BS", type: "percentage" },
      { text: "所有權人地址", value: "OA", width: "200px" },
    ],
    row_span_headers: [
      "SN",
      "Owner",
      "TLA",
      // "BN",
      // "BA",
      // "BN",
      // "MBA",
      // "BL",
      // "ABA",
      "LS",

      "TBA",
      "BS",
      "OA",
    ],
    showDecimalDialog: false,
    tempDecimalPlaces: 3,
  }),
  mixins: [UrbanMixin],
  components: {
    datetime: Datetime,
  },
  computed: {
    ...mapState(["token", "decimal_places"]),
  },
  methods: {
    ...mapMutations(['set_decimal_places']),
    saveDecimalPlaces() {
      this.set_decimal_places(this.tempDecimalPlaces);
      this.showDecimalDialog = false;
    },
    ...mapMutations(["setTitle"]),
    close() {
      this.progress_dialog = false;
    },
    onTranscribeFinal() {
      if (this.prop_emit) {
        this.$emit("onTranscribeFinal");
      }
      this.progress_dialog = false;
    },
    onRetrieveTranscribe() {
      this.progress_message = {
        title: this.$t("plan.transcribe"),
        text: "取得資料中...",
      };
      this.progress_dialog = true;
      // delay 1 second to call this.getTranscribe();
      setTimeout(() => {
        this.getTranscribe();
      }, 500);
    },
    exportTranscribe() {
      this.onExporting();
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/export/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: "blob",
      };
      const data = {
        urban_id: this.choose_urban.id,
        decimal_places: this.decimal_places
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          let filename = `${currentObj.choose_urban.name}_繕打.xlsx`;
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.onExportDone();
        });
    },
    onExporting() {
      this.progress_message = {
        title: this.$t("plan.transcribe"),
        text: "匯出資料中...",
      };
      this.progress_dialog = true;
    },
    onExportDone() {
      this.progress_dialog = false;
    },
    forceFileDownload(response, filename) {
      console.log("forceFileDownload:", filename);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
    },
    displayItem(value, type) {
      switch (type) {
        case "percentage":
          return this.showPercent(value);
        case "float":
          return this.showFloat(value);
        default:
          return value;
      }
    },
    showPercent(value) {
      if (value == null) {
        return "";
      }
      try {
        let val = parseFloat(value.toFixed(4));
        return (val * 100).toFixed(2) + "%";
      } catch (error) {
        return value;
      }
    },
    showFloat(value) {
      if (value == null || value == "") {
        return "";
      }
      try {
        return value.toFixed(this.decimal_places);
      } catch (error) {
        return value;
      }
    },
    allowTD(item, key, span) {
      if (
        this.row_span_headers.includes(key) &&
        (span == null || span == undefined)
      ) {
        return false;
      }
      if (!this.row_span_headers.includes(key)) {
        return true;
      }
      // if owner is not null or empty return true
      if (item.Owner != null && item.Owner != "") {
        return true;
      }
      return false;
    },
    calculateRowSpan(item, key) {
      // if key is not in row_span_headers, return 1
      // console.log("calculateRowSpan", item);
      if (!this.row_span_headers.includes(key)) {
        return 1;
      }
      // if item.Owner is null or empty, return 1
      if (item.Owner == null || item.Owner == "") {
        return 1;
      }
      // console.log("calculateRowSpan", item.Owner, key, item.rowsapn, "..");
      // if item.rowsapn is not null, return item.rowsapn else return 1
      return item.rowsapn ? item.rowsapn : 1;
    },
    exportData() {
      if (this.choose_urban == null) {
        return;
      }
      if (this.prop_emit == true) {
        this.$emit("export");
        return;
      }
      this.exportTranscribe();
    },
  },
  watch: {
    options: {
      handler() {
        this.getTranscribe();
      },
    },
  },
  mounted() {
    this.getUrbanRenewal();
    this.setTitle(this.$i18n.t("title.Transcribe"));
    this.tempDecimalPlaces = this.decimal_places
  },
};
</script>

<style lang="sass" scoped>
.theme--light.v-list-item:hover::before
    opacity: 0.4
.v-list-item--link:before
    background-color: #ff9800

.customer-header
  color: #00791e !important
  border: thin solid rgba(0, 0, 0, 0.12)

.customer-header-small
  @extend .customer-header
  width: 80px
  min-width: 80px

.customer-header-medium
  @extend .customer-header
  width: 100px
  min-width: 100px

.customer-header-name
  @extend .customer-header
  width: 150px
  min-width: 150px

.customer-header-address
  @extend .customer-header
  width: 200px
  min-width: 200px
</style>
