<template>
  <Transcribe
    prop_title="title.Agreement"
    ref="transcribe"
    :prop_emit="emit_result"
    v-on:export="exportAgreement()"
    v-on:onTranscribeFinal="onTranscribeFinal()"
  >
    <!-- PANEL -->
    <template v-slot:panel>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="choose_urban"
            :items="urbans"
            item-text="name"
            item-value="id"
            :label="$t('issue.chooseUrban')"
            hide-details
            persistent-hint
            return-object
            filled
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="choose_template"
            :items="templates"
            item-text="text"
            item-value="value"
            :label="$t('plan.export-templates')"
            hide-details
            persistent-hint
            filled
          ></v-select>
        </v-col>
        <v-col cols="3" class="text-right">
            <v-btn color="primary" dark class="mx-2" @click="getTranscribe()" :disabled="!choose_urban">
              <v-icon left>mdi-pencil-plus</v-icon>
              {{ $t("plan.retrieve") }}
            </v-btn>
            <v-btn color="secondary" dark class="mx-2" @click="exportAgreement()" v-show="choose_urban">
              <v-icon left>mdi-pencil-plus</v-icon>
              {{ $t("plan.export-transcribe") }}
            </v-btn>
          </v-col>
      </v-row>
    </template>
  </Transcribe>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import Transcribe from "./Transcribe.vue";
import UrbanMixin from "../common/UrbanMixin.vue";
export default {
  data: (vm) => ({
    choose_urban: null,
    emit_result: true,
    dialog_message: false,
    message: {
      title: "",
      text: "",
    },
    templates: [
      {text: "新北市自行組織更新會同意書", value: "新北市自行組織更新會同意書"},
      {text: "台北市都市更新事業計畫同意書", value: "台北市都市更新事業計畫同意書"},
      {text: "台北市事業概要同意書", value: "台北市事業概要同意書"},
    ],
    choose_template: "新北市自行組織更新會同意書"
  }),
  mounted() {
    this.getUrbanRenewal();
    this.setTitle(this.$i18n.t("title.Agreement"));
  },
  mixins: [UrbanMixin],
  components: {
    datetime: Datetime,
    Transcribe,
  },
  computed: {
    ...mapState(["token", "decimal_places"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getTranscribe() {
      this.$refs.transcribe.choose_urban = this.choose_urban;
      this.$refs.transcribe.onRetrieveTranscribe();
    },
    onTranscribeFinal() {
      console.log("onTranscribeFinal in Agreement");
    },
    exportAgreement() {
      this.onExporting();
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/export_agree/`;
      // set choose_urban to transcribe
      this.$refs.transcribe.choose_urban = this.choose_urban;
      let urban_name = this.$refs.transcribe.choose_urban.name;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: "blob",
      };
      console.log(this.choose_template);
      const data = {
        urban_id: this.$refs.transcribe.choose_urban.id,
        decimal_places: this.decimal_places,
        template: this.choose_template
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          currentObj.loading = false;
          
          // Get filename from Content-Disposition header if available
          let filename = `${urban_name}_${currentObj.choose_template}.zip`;
          
          // Find the Content-Disposition header (case-insensitive)
          let contentDisposition = null;
          for (const key in response.headers) {
            if (key.toLowerCase() === 'content-disposition') {
              contentDisposition = response.headers[key];
              console.log('Found Content-Disposition header:', contentDisposition);
              break;
            }
          }
          
          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="([^"]+)"/i);
            if (filenameMatch && filenameMatch[1]) {
              filename = filenameMatch[1];
              console.log("Extracted filename:", filename);
            }
          } else {
            console.log('Content-Disposition header not found. Headers:', response.headers);
          }
          
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          if (error.response && error.response.data) {
            const reader = new FileReader();
            reader.onload = function () {
              const errorData = JSON.parse(this.result);
              alert(errorData.error_message);
            };
            reader.readAsText(error.response.data);
          }
        })
        .finally(() => {
          this.onExportDone();
        });
    },
    onExporting() {
      this.$refs.transcribe.progress_message = {
        title: this.$t("plan.agreement"),
        text: "匯出資料中...",
      };
      this.$refs.transcribe.progress_dialog = true;
    },
    onExportDone() {
      this.$refs.transcribe.progress_dialog = false;
    },
    onRetrieveTranscribe() {
      // this.$refs.transcribe.onRetrieveTranscribe();
    },
  },
};
</script>

<style lang="sass" scoped>
.theme--light.v-list-item:hover::before
    opacity: 0.4
.v-list-item--link:before
    background-color: #ff9800
</style>
